<template>
  <Layout :tituloPagina="`Network | Routers | Edición | ${router.nombre} | Proveedores de internet | ${modo == 'nuevo' ? 'Nuevo' : isp.nombre+' | Edición'}`">
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              {{ modo == 'nuevo' ? 'Nuevo' : '' }}
              {{ modo == 'edicion' ? 'Edicion de' : '' }}
              proveedor de internet para el router {{ router.nombre }}
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-2" v-show="isp.id != null">
                <label>ID</label>
                <input
                  type="text"
                  v-model="isp.id"
                  class="form-control"
                  placeholder="ID"
                  readonly
                />
              </div>
              <div :class="{
                  'col-md-6': isp.id == null,
                  'col-md-4': isp.id != null
                }">
                <label>Nombre</label>
                <input
                  ref="nombre"
                  type="text"
                  v-model="isp.nombre"
                  class="form-control"
                  maxlength="45"
                  placeholder="Nombre del proveedor"
                />
              </div>
              <div class="col-md-3 col-6">
                <label>Principal</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="isp.principal"
                    @change="isp.principal = !isp.principal"
                    class="form-check-input"
                    type="checkbox"
                    id="banderaPrincipal"
                  />
                  <label class="form-check-label" for="banderaPrincipal">
                    {{isp.principal ? 'Si':'No'}}
                  </label>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <label title="Palanca que habilita o deshabilita el control de reuso sobre el servicio del ISP actual">
                  Reuso
                </label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="isp.reuso"
                    @change="isp.reuso = !isp.reuso"
                    class="form-check-input"
                    type="checkbox"
                    id="banderaReuso"
                  />
                  <label class="form-check-label" for="banderaReuso">
                    {{isp.reuso ? 'Si':'No'}}
                  </label>
                </div>
              </div>
            </div>
            <div class="row m-t-xs">
              <div class="col-md-6">
                <label>Carga</label>
                <div class="row">
                  <div class="col-sm-7 col-7">
                    <input
                      type="number"
                      class="form-control text-right"
                      ref="cargaMaxima"
                      step="1024"
                      min="0"
                      max="2147483647"
                      v-model="isp.carga_maxima"
                      @change="
                        isp.carga_maxima = valorValido(isp.carga_maxima)
                      "
                      onclick="this.select()"
                    />
                  </div>
                  <div class="col-sm-5 col-5">
                    <select class="form-select" v-model="isp.unidad_carga">
                      <option value="k">Kbps</option>
                      <option value="M">Mbps</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label>Descarga</label>
                <div class="row">
                  <div class="col-sm-7 col-7">
                    <input
                      type="number"
                      class="form-control text-right"
                      ref="descargaMaxima"
                      step="1024"
                      min="0"
                      max="2147483647"
                      v-model="isp.descarga_maxima"
                      @change="
                        isp.descarga_maxima = valorValido(isp.descarga_maxima)
                      "
                      maxlength="9"
                      onclick="this.select()"
                    />
                  </div>
                  <div class="col-sm-5 col-5">
                    <select
                      class="form-select"
                      v-model="isp.unidad_descarga"
                    >
                      <option value="k">Kbps</option>
                      <option value="M">Mbps</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-t-xs">
              <div class="col-lg-12">
                <label>Descripción del servicio</label>
                <textarea
                  cols="30"
                  rows="5"
                  v-model="isp.descripcion"
                  class="form-control"
                  placeholder="Descripción acerca del proveedor"
                ></textarea>
              </div>
            </div>

            <hr class="hr-line-dashed">
            <h5 class="text-truncate w-100">
              Configuración para probar el servicio de internet
            </h5>
            <div class="row">
              <div class="col-lg-6">
                <label>Dirección IP de host</label>
                <div class="input-group">
                  <input
                    type="text" ref="ipPruebasPing"
                    class="form-control text-right" placeholder="8.8.4.4"
                    minlength="7" maxlength="15" size="15" 
                    v-model="isp.ip_pruebas_ping"
                  />
                  <button class="btn btn-info" @click="informacionDireccionIpHostPruebasVisible=!informacionDireccionIpHostPruebasVisible">
                    <i class="fas fa-info"></i>
                  </button>
                </div>

                <div v-show="informacionDireccionIpHostPruebasVisible">
                  <p>
                    La dirección IP de host se usa para realizarle pruebas de ping y validar que el servicio de internet
                    se encuentra operando de forma correcta. 
                  </p>
                  <p>
                    Si la dirección IP no se define, no se mostrará el estado del servicio.
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <label>Cantidad de pruebas ping</label>
                <input
                  type="number" ref="cantidadPruebasPing"
                  class="form-control text-right" placeholder="5"
                  oninput="this.value = Math.abs(this.value)"
                  onclick="this.select()" v-model="isp.cantidad_pruebas_ping"
                />
              </div>

              <div class="col-md-3" v-show="isp.ip_pruebas_ping != null && isp.ip_pruebas_ping != ''">
                <label class="m-t-xs">Estado del servicio</label> <br>
                <span class="badge bg-gradient font-size-12 mt-2"
                  :class="{
                    'bg-light': isp.estado_servicio == 0,
                    'bg-primary': isp.estado_servicio == 1,
                    'bg-danger': isp.estado_servicio == 2
                  }">
                  {{ isp.estado_servicio == 0 ? 'Se actualizará en un momento': '' }}
                  <span v-show="isp.estado_servicio == 0" class="mdi mdi-loading mdi-spin"></span>
                  {{ isp.estado_servicio == 1 ? 'Servicio funcional': '' }}
                  {{ isp.estado_servicio == 2 ? 'Servicio caido' : '' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">Configuración de red</h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <label>Interfaz del servicio de internet</label>
                <select class="form-select" v-model="isp.id_interfaz">
                  <option :value="null">Seleccionar</option>
                  <option
                    v-for="interfaz in router.interfaces"
                    :key="interfaz.id"
                    :value="interfaz.id"
                    v-show="interfaz.modo != 'LAN'"
                  >
                    {{ interfaz.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label>NAT</label>
                <select class="form-select" v-model="isp.nat">
                  <option value="1">Si</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>

            <div v-if="isp.id_interfaz != null && isp.id_interfaz != 0">
              <label>Configuración</label>
              <select class="form-select" v-model="isp.dhcp">
                <option value="1">{{interfazSeleccionada && interfazSeleccionada.tipo == 'pp1'?'Automática': 'Mediante DHCP'}}</option>
                <option value="0">Con dirección IP estática</option>
              </select>
            </div>

            <div v-if="isp.dhcp == 0">
              <label>Dirección IP de la interfaz</label>
              <input
                type="text"
                class="form-control"
                placeholder="10.1.2.2/24"
                v-model="isp.direccion_ip"
              />
              <label>Puerta de enlace</label>
              <input
                type="text"
                class="form-control"
                placeholder="10.1.2.1"
                v-model="isp.puerta_enlace"
              />
            </div>
          </div>
        </div>

        <!-- <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Habilitar o deshabilitar el historial del tráfico
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="form-check-success form-check-switch-right form-switch form-switch-lg">
              <input
                :checked="isp.ht"
                @change="isp.ht = !isp.ht"
                class="form-check-input"
                type="checkbox"
                id="historialTrafico"
              />
              <label class="form-check-label" for="historialTrafico">
                Proveedor ISP actual
              </label>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary" @click="atras()">
          <i class="mdi mdi-chevron-left"></i>
          Atrás
        </button>
        <button
          class="btn btn-success"
          @click="modo == 'nuevo' ? guardar() : actualizar()"
          :disabled="bandera_spinner"
        >
          <i 
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"

import IspRouterSrv from '@/services/IspRouterSrv.js'
import RouterSrv from '@/services/RouterSrv.js'
export default {
  name: 'EdicionProveedorInternetRouter',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      baseUrl: window.location.origin, 
      router: { id: null, interfaces: []},
      isp: {
        id: null,
        nombre: '',
        principal: false,
        reuso: false,
        descripcion: '',
        id_router: null,
        carga_maxima: 0,
        descarga_maxima: 0,
        id_interfaz: null,
        dhcp: 1,
        direccion_ip: '',
        puerta_enlace: '',
        unidad_carga: 'k',
        unidad_descarga: 'k',
        nat: 1,
        ip_pruebas_ping: '',
        estado_servicio: 0,
        cantidad_pruebas_ping: 5,
        ht: 0,
        ht_sinc: 1
      },
      ispInicial: {},
      bandera_spinner: false,
      informacionDireccionIpHostPruebasVisible: 0
    }
  },

  watch: {
    'isp.ht': function(newVar, oldVar) {
      this.isp.ht_sinc = false
    }
  },

  computed: {
    interfazSeleccionada() {
      let self = this

      if(self.router.interfaces.length == 0) return;
      if(self.isp.id_interfaz == null) return;

      let interfacesFiltradas = self.router.interfaces.filter(interfaz => { 
        return interfaz.id == self.isp.id_interfaz 
      })

      if( interfacesFiltradas.length == 0 ) return;

      return interfacesFiltradas[0]
    }
  },

  created () {
    var self = this

    // Carga del modo
    if (self.$route.path.indexOf('nuevo') == -1) self.modo = 'edicion'

    self.cargarRouter()

    // Carga de la relación del ISP con el router especificado
    var idRouter = this.$route.params.id
    self.isp.id_router = idRouter

    // Carga de una copia inicial del ISP
    self.ispInicial = Object.assign({}, self.isp)

    // Si está en modo "nuevo"
    if (self.modo == 'edicion') self.cargarIsp()
  },

  methods: {
    atras() {
      this.$router.go(-1)
    },

    cerrar() {
      this.atras()
    },

    cargarIsp() {
      var self = this
      var idIsp = this.$route.params.id_isp

      IspRouterSrv.ispJSON(idIsp).then(response => {
        self.isp = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cargarRouter() {
      var self = this
      var idRouter = this.$route.params.id

      RouterSrv.routerJSON(idRouter).then(response => {
        let router = response.data
        self.router = router
        if (router.interfaces == undefined || router.interfaces.length == 0) {
          iu.msg.warning(
            'Conecta tu router a ArgusBlack para que se sincronicen las interfaces y puedas registrar los ISPs'
          )
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    actualizar() {
      var self = this
      var isp = Object.assign({}, this.isp)

      self.bandera_spinner = true

      if (isp.nombre.length > 45) {
        iu.msg.warning('No se puede agregar un Nombre mayor a 45 caracteres.')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (isp.unidad_carga == 'k' && isp.carga_maxima > 10485760) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 10485760')
        self.$refs.cargaMaxima.select()
        self.bandera_spinner = false
        return
      } else if (isp.unidad_carga == 'M' && isp.carga_maxima > 10240) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 10240')
        self.$refs.cargaMaxima.select()
        self.bandera_spinner = false
        return
      }

      if (isp.unidad_descarga == 'k' && isp.descarga_maxima > 10485760) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 10485760')
        self.$refs.descargaMaxima.select()
        self.bandera_spinner = false
        return
      } else if (isp.unidad_descarga == 'M' && isp.descarga_maxima > 10240) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 10240')
        self.$refs.descargaMaxima.select()
        self.bandera_spinner = false
        return
      }

      if (isp.id_interfaz == null) {
        iu.msg.warning('Es necesario seleccionar una interfaz')
        self.bandera_spinner = false
        return
      }

      // Verficación de la dirección IP y la puerta de enlace
      if (isp.dhcp == 0 && isp.direccion_ip == '') {
        iu.msg.warning('Es necesaria una dirección IP para la interfaz')
        self.bandera_spinner = false
        return
      }

      if (isp.dhcp == 0 && isp.puerta_enlace == '') {
        iu.msg.warning('Es necesaria una dirección IP de la puerta de enlace')
        self.bandera_spinner = false
        return
      }

      IspRouterSrv.actualizar(isp).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }

        // Si está presente error.response.data.errors
        if ('errors' in error.response.data) {
          mensaje += '<br><br>'
          Object.keys(error.response.data.errors).forEach(key => {
            mensaje += `${error.response.data.errors[key]} <br>`

            if(key == 'ip_pruebas_ping') {
              self.$refs.ipPruebasPing.select()
              self.$refs.ipPruebasPing.focus()
            }
          })
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    guardar() {
      var self = this
      var isp = Object.assign({}, this.isp)

      self.bandera_spinner = true

      if (isp.nombre.length > 45) {
        iu.msg.warning('No se puede agregar un Nombre mayor a 45 caracteres.')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (isp.id_interfaz == undefined || isp.id_interfaz == null) {
        if (self.router.interfaces == undefined || self.router.interfaces.length == 0)
          iu.msg.warning('Es necesario enlazar tu router a ArgusBlack para sincronizar las interfaces')
        else iu.msg.warning('Es necesario seleccionar una interfaz')

        self.bandera_spinner = false
        return
      }

      if (isp.unidad_carga == 'k' && isp.carga_maxima > 10485760) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 10485760')
        self.$refs.cargaMaxima.select()
        self.bandera_spinner = false
        return
      } else if (isp.unidad_carga == 'M' && isp.carga_maxima > 10240) {
        iu.msg.warning('Es necesario seleccionar un valor menor que 10240')
        self.$refs.cargaMaxima.select()
        self.bandera_spinner = false
        return
      }

      if (isp.unidad_descarga == 'k' && isp.descarga_maxima > 10485760) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 10485760')
        self.$refs.descargaMaxima.select()
        self.bandera_spinner = false
        return
      } else if (isp.unidad_descarga == 'M' && isp.descarga_maxima > 10240) {
        iu.msg.warning('Es necesario seleccionar un valor menor a 10240')
        self.$refs.descargaMaxima.select()
        self.bandera_spinner = false
        return
      }

      IspRouterSrv.guardar(isp).then(response => {
        let id = response.data
        iu.msg.success('Se guardó correctamente')
        self.isp.id = id
        self.$router.replace({
          name: 'edicionProveedorInternetRouter',
          params: { id_isp: self.isp.id }
        })

        self.modo = 'edicion'
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }

        // Si está presente error.response.data.errors
        if ('errors' in error.response.data) {
          mensaje += '<br><br>'
          Object.keys(error.response.data.errors).forEach(key => {
            mensaje += `${error.response.data.errors[key]} <br>`

            if(key == 'ip_pruebas_ping') {
              self.$refs.ipPruebasPing.select()
              self.$refs.ipPruebasPing.focus()
            }
          })
        }
        
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    valorValido(valor) {
      if (isNaN(valor) || valor <= 0) {
        iu.msg.warning('Es necesario un valor mayor a 0')
        return 1
      }

      valor = parseFloat(valor)

      if (valor != valor.toFixed(3)) {
        iu.msg.warning('Solo se permite una precisión de 3 decimales')
        return valor.toFixed(3)
      }

      return valor
    }
  }
}
</script>